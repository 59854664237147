import React, { useState, useEffect } from 'react';
import cookies from 'next-cookies'
import classNames from 'classnames';
import { Footer as FooterDesign } from '../components/Footer/Footer';

import { useAuth } from '../providers/Auth';

import '../styles/components/footer.scss';

const Footer = ({ className, pathName, isBengkel }) => {
  const [path, setPath] = useState("");
  const [userMode, setUserMode] = useState(true);
  const { authToken } = useAuth();

  useEffect(() => {
    if (cookies().mode === 'user' || !cookies().mode) {
      setUserMode(true);
    } else {
      setUserMode(false);
    }

    if (path === "") {
      setPath(window.location.pathname);
    }
  }, []);

  if (
    [
      '/account/login', '/account/register',
      '/bantuan-zendesk', '/plans', '/compare',
      '/contact-us/[lead_id]', '/payment-link/[id]',
      '/agents/register/[slug]',
      '/privasi', '/ketentuan', '/', '/tentang', '/cara-beli'
    ].includes(pathName)
  ) {
    return null;
  }

  {/* Only show regular footer on homepage and user hasn't logged in */ }
  const defineShowRegFooter = () => {
    if (typeof window !== 'undefined') {
      if (!authToken) {
        return path === "/" || isBengkel;
      }
      return false;
    }

    return true;
  }

  return (
    <div className={classNames('pt-3', 'footer__wrapper', className, pathName === '/agents/invite' && 'shadow-none')}>
      <div className="container">
        <FooterDesign
          isAgent={!userMode}
          showRegularFooter={defineShowRegFooter()}
          showBengkelFooter={isBengkel}
        />
      </div>
    </div>
  );
}
export default Footer;
