import { useMemo } from 'react';
import jsCookie from 'js-cookie';

import Router from 'next/router';

import { toast } from 'react-toastify';

import { ApolloClient, HttpLink, ApolloLink, InMemoryCache, from, concat } from '@apollo/client';
import { onError } from "@apollo/client/link/error";
import { concatPagination } from '@apollo/client/utilities';
import { nearbyInsurancePolicy } from '../services/insuranceProvider';

import merge from 'deepmerge';
import isEqual from 'lodash/isEqual';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

let apolloClient;

const httpLink = new HttpLink({
  uri: process.env.NEXT_PUBLIC_APOLLO_CLIENT
});

const clientMiddleware = new ApolloLink((operation, forward) => {
  const token = jsCookie.get('token');
  const customHeaders = operation.getContext().hasOwnProperty("headers") ? operation.getContext().headers : {};

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      ...customHeaders,
      ...(customHeaders && customHeaders.authorization ? {} : { authorization: token ? `Bearer ${token}` : null, }),
    }
  }));

  return forward(operation);
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      )
      if (message.includes('User not found')) {
        toast.error('Anda belum terdaftar, mohon daftar terdahulu');
        Router.push('/account/register/');
      } else {
        toast.error(message);
      }
    });

  if (networkError) toast.error('something went wrong!');
});

function createApolloClient() {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: from([errorLink, concat(clientMiddleware, httpLink)]),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            allPosts: concatPagination(),
            ...nearbyInsurancePolicy,
          },
        },
      },
    })
  })
};

export function initializeApollo(initialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the initialState from getStaticProps/getServerSideProps in the existing cache
    const data = merge(existingCache, initialState, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        ),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
};

export function addApolloState(client, pageProps) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
};

export function useApollo(pageProps) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state), [state]);
  return store;
};
